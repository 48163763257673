import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from '../../Router';
import '../Login/Login.scss';
import {Fab} from '@mui/material';
import {useIntl} from "react-intl";

export function Confirmation() {
    const navigate = useNavigate();
    const intl = useIntl();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        navigate(AppRoutes.PROFILE_OPTIONS, {replace: true});
    }
    useEffect(() => {
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);

    return (
        <>
                <div className='img-logo-mobile-profiles'/>
                <br />
                <div
                    className='title-confirm'>
                <br />
                        {intl.formatMessage({id: "client_successfully_credit_application"})}
                </div>
                <div
                    className='subtitle-confirm'>
                <br />
                        {intl.formatMessage({id: "client_successfully_credit_application_note"})}
                </div>
                <div className='confirm-container'>
                    <Fab className="form-control-small-button backgound-color" variant="extended" type='submit'
                        size="medium"
                        color="error" aria-label="add" onClick={handleSubmit}>
                        {intl.formatMessage({id: "button_go_to_init"})}
                    </Fab>
                </div>
        </>
    )
}