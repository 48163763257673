import {ArrowBack,Search} from "@mui/icons-material";
import {Fab} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Col, FormGroup, Input, Row} from "reactstrap";
import {AppRoutes} from "../../Router";
import './Catalog.scss';
import {inventario} from "../../__mocks__/data";
import {WLOCreditAPIService} from "../../services";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import {useLoginContext} from "../../hooks/useLoginContext";
import * as _ from 'lodash';
import {useRequestContext} from "../../hooks/useRequestContext";
import ResponsiveAppBar from "../../components/shared/Menubar/ResponsiveAppBar";
interface CatalogProps {
}

export function Catalog(_props: CatalogProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [isLoading, setIsLoading] = useState(false);
    const [valorBusqueda, setBusqueda] = useState("");
    const [inventarioWLO, setInventarioWLO] = useState({} as any);
    const [catalogo, setCatalogo] = useState([] as any);
    const intl = useIntl();
    const navigate = useNavigate();

    const handleSubmit = () => {
        if(user.istipo=="2"){
            navigate(AppRoutes.LOGINAUTO, {replace: true});
        }else{
            navigate(AppRoutes.MENU_USER, {replace: true});
        }
    };

    const handleSubmitCredit = (name: any, price: any, imagen:any) => {
        setRequest({nameProduct: name, priceProduct: price, searchANI: false, errorConsulta: false,imagemoto:imagen})
        navigate(AppRoutes.HOME, {replace: true});
    };

    useEffect(() => {
            fechInventary();
    }, [user]);

    const fechInventary = async () => {
        const catalogosval = await WLOCreditAPIService.getInventario({
            pws_tip_producto: "1",
            pws_cod_producto: "",
            pws_cate_producto: ""

        });
        if(catalogosval.payload.data.RespDetCat){
            if(catalogosval.payload.data.RespDetCat.RespuestaDetCat.length >=1  &&  
                catalogosval.payload.data.RespDetCat.RespuestaDetCat[0].R_s_nom_cate != "Error de ejecución" &&
                catalogosval.payload.data.RespDetCat.RespuestaDetCat[0].R_s_cod_cate > 0 ){
                setInventarioWLO(catalogosval.payload.data.RespDetCat.RespuestaDetCat)
                console.log(catalogosval.payload.data.RespDetCat.RespuestaDetCat, "hola")
                setIsLoading(false)
            }else{
                let catalogoval =[
                {R_s_cod_cate: "1",R_s_nom_cate: "LIBRANZA"},]
                console.log(catalogoval)
                setCatalogo(catalogoval)
            }

        }
        getInfoProductos({
            pws_tip_producto: "",
            pws_cod_producto: "",
            pws_cate_producto: ""
        })
        
        return catalogosval;
    }
    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3200);
        return () => clearTimeout(timer);
    }, []);
    const getInfoProductos = async (data:any)=>{
        if(data.pws_cate_producto == "-1"){
            data.pws_cate_producto =""
        }
        const result = await WLOCreditAPIService.getInventario(data);
        try {
            if (_.isEmpty(result.payload.data.RespProd == null)) {
                setInventarioWLO(null);
            }
        } catch (error) {
            
        }
        let catalogoval =[
            {R_s_cod_cate: result.payload.data.RespProd.RespuestaProd[0].R_s_cod_prod, R_s_nom_cate: result.payload.data.RespProd.RespuestaProd[0].R_s_nom_prod},]
        setCatalogo(catalogoval);   
        setInventarioWLO(result.payload.data);
    }
    

    const renderInventary = () => {
        const showInventary = inventarioWLO && !isLoading;
        if (showInventary) {
            const tempInventary = inventarioWLO as any;
            if (tempInventary && !_.isEmpty(tempInventary.payload.data.RespProd)) {
                return (
                    <>
                        <Col md={2}/>
                        
                    </>
                )
            }
        } else {
            return (<div>{'Cargando'}</div>);
        }
    }
    const handleSearch=async (evento:any)=>{
        setBusqueda(evento.target.value.catalogosval.payload.data.RespDetCat.RespuestaDetCat)
    }   
    const searchbutton= async ()=>{
        getInfoProductos({
            pws_tip_producto: "",
            pws_cod_producto: "",
            pws_cate_producto: ""
        })
    }
    const renderInventaryLocal = () => {
        const localInventary = inventarioWLO;
        const showInventary = localInventary && !isLoading;
        if (showInventary) {
            if (localInventary && !_.isEmpty(localInventary)) {
               return localInventary.RespProd.RespuestaProd.map((valor:any)=>{
                if(valorBusqueda){
                    if(valor.R_s_nom_prod.toLocaleLowerCase().includes(valorBusqueda.toLocaleLowerCase())){
                        return (
                            <>
                                <Col md={1}/>
                                <Col
                                    md={2}
                                    onClick={() => handleSubmitCredit(valor.R_s_nom_prod, valor.R_s_precio_comer,valor.R_s_link_img)}>
                                    <img className={'img-fluid'} style={{height:"200px", borderRadius:"40px"}} src={valor.R_s_link_img} alt={''} />
                                    <h1 className="title-model">{valor.R_s_nom_prod}</h1>
                                </Col>
                                <Col md={1}/>
                               
                            </>
                        )
                    }else{
                        return("")
                    }
                }else{
                    return (
                        <>
                            <Col md={1}/>
                            <Col
                                md={2}
                                onClick={() => handleSubmitCredit(valor.R_s_nom_prod, valor.R_s_precio_comer,valor.R_s_link_img)}>
                                <img className={'img-fluid'}  style={{height:"200px", borderRadius:"40px"}}src={valor.R_s_link_img} alt={''}/>
                                <h1 className="title-model">{valor.R_s_nom_prod}</h1>
                            </Col>
                            <Col md={1}/>
                           
                        </>
                    )
                }
                   
                })
                
                
            }
        } else {
            return (<div>{'Cargando'}</div>);
        }
    }

    return (

        <div>
            <ResponsiveAppBar/>
            <ClockLoader id='spinner' color={"#00ab7e"} loading={isLoading} size={100}/>
            <div className="flex-container-catalog">
                <div className="flex-item-left-catalog">
                    <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmit} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {intl.formatMessage({id: "button_back"})}
                    </Fab>
                </div>
                <div className="flex-item-right-catalog">
                    <h1 className="title-motos-catalog">{intl.formatMessage({id: "motorcycles"})}</h1>
                    <h3 className="subtitle-motos-catalog">{intl.formatMessage({id: "choose_the_one_of_your_preference"})}</h3>
                </div>
                <div className="flex-item-left-catalog">
                </div>
            </div>
            <Row className="flex-item-right-catalog">
                <Col md={6}>
                {/* <FormGroup>
                        <div style={{display:"flex"}}>
                            <label>Filtro de busqueda por categorias</label>
                        <Input
                            className='form-control-search-catalog'
                            id="name"
                            name="name"
                            onBlur={handleSearch}
                            placeholder={intl.formatMessage({id: "do_search_catalog"})}
                            type="select"
                        >
                            <option value="">Selecciona una categoria</option>
                            {catalogo.map((valor:any)=>{
                            return(<option value={valor.R_s_cod_cate}>{valor.R_s_nom_cate}</option>)
                        })}
                        </Input>
                                <button onClick={searchbutton} className="buttonSearch">
                            <Search />
                            </button>
                        </div>
                       
                    </FormGroup> */}
                </Col>
                <Col md={6}/>
                {renderInventaryLocal()}
            </Row>
        </div>
    )
}